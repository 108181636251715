
import {getCookie, setCookie, checkCookieAndSet} from "../cookieFunctions";
function RunColourFunction(){
document.addEventListener('DOMContentLoaded', function () {

  //RESET BUTTON

 var easyReadButton = document.getElementById("easyReadButton"); //select2-dropdown
 var text = document.getElementsByTagName('*'); //textarea
 var link = document.getElementsByTagName('a');
 var ul = document.getElementsByTagName('ul');
 var textarea = document.getElementsByTagName('textarea');
 var option = document.getElementsByTagName('option');
 var select2dropdown = document.getElementsByClassName('select2-results__option');
 var input = document.getElementsByTagName('input');
 var li = document.getElementsByTagName('li');
 var span = document.getElementsByTagName('span');
 var select = document.getElementsByTagName('select');
 var body = document.getElementsByTagName('body');
 var div = document.getElementsByTagName('div'); 
 var i = document.getElementsByTagName('i'); 
 var button = document.getElementsByTagName('button');
 var wpbutton = document.getElementsByClassName('wp-block-button__link'); 
 var classbutton = document.getElementsByClassName('button'); 
 var accessibilityToolBox = document.getElementsByClassName('accessibilityToolBox');
 var svgEl = document.querySelectorAll('svg');
 var darkColour = 'rgb(16 16 16 / 101%)';
 var whiteColour = 'rgb(256 256 256 / 97%)';
 var yelloColour = 'rgb(255 206 0 / 101%)';
 var borderStyle = 'solid 1.5px';
 var borderColorYellow = borderStyle +" "+yelloColour;
 var borderColorWhite = borderStyle +" "+whiteColour;
 var borderColorDark = borderStyle +" "+darkColour;
 var easyReadSetCookie = checkCookieAndSet('easyRead', '0', 1);

 checkCookie(getCookie('easyRead'));
  
  /*
  function runCookieChecker(myCookie){
    if (myCookie == undefined || myCookie == 0) {
      setCookie('easyRead', '1', 1);
      checkCookie(getCookie('easyRead'));
      console.log(getCookie('easyRead'));
    } if (increaseSize <= 2) {
      setCookie('easyRead', '0', 1);
      checkCookie(getCookie('easyRead'));
      console.log(getCookie('easyRead'));
    }
  }
*/
//setCookie('easyRead', '0', 1);

function runCookieChecker(myCookie){

  switch(myCookie) {
    case '0':
      // code block
      setCookie('easyRead', '1', 1);
      checkCookie(getCookie('easyRead'));
      break;
    case '1':
        // code block
          setCookie('easyRead', '2', 1);
          checkCookie(getCookie('easyRead'));
          break;
    case '2':
      // code block
        setCookie('easyRead', '0', 1);
        checkCookie(getCookie('easyRead'));
        break;
    default:
        // code block
        setCookie('easyRead', '0', 1);
        checkCookie(getCookie('easyRead'));
        break;
  }

/* }
  console.log("This Cookie: "+ myCookie)
  if (myCookie == undefined) {
    setCookie('easyRead', '0', 1);
    checkCookie(getCookie('easyRead'));
  }
  else if (myCookie <= 2) {
    var newSize = parseFloat(myCookie) + 1;
    setCookie('easyRead', newSize, 1);
    checkCookie(getCookie('easyRead'));
  } else if (myCookie > 2) {
    setCookie('easyRead', 0, 1);
    checkCookie(getCookie('easyRead'));
  }
  */
}

  if(easyReadButton !== null){
    easyReadButton.onclick = function() {
      runCookieChecker(getCookie('easyRead'));
    }
   }


    function checkCookie(myCookie){

      console.log("checkCookie: "+myCookie)
    if(myCookie == 0){

      function changeSVGEl(element) {
        for (var i = 0; i < element.length; i++) {
          //element[i].style.color = 'red';
          //element[i].setAttribute ('style', 'background-color: red !important;');
          //input
          let firstElementChild = element[i].firstElementChild;
          firstElementChild.style.setProperty ('fill', whiteColour, 'important');  
          firstElementChild.style.setProperty ('fill', whiteColour, 'important'); 
          firstElementChild.style.setProperty ('stroke', whiteColour, 'important'); 
        }
      } 

        function changeButton(element) {
          for (var i = 0; i < element.length; i++) {
            //element[i].style.color = 'red';
            //element[i].setAttribute ('style', 'background-color: red !important;');
  
            element[i].style.setProperty ('background-color', yelloColour, 'important');
            element[i].style.setProperty ('background', yelloColour, 'important');
            element[i].style.setProperty ('color', darkColour, 'important');

            var children = element[i].firstElementChild;
            if(children){  
              children.style.setProperty ('mix-blend-mode', 'difference', 'important');
              children.style.setProperty('background-color', 'none', 'important');
              children.style.setProperty ('mix-blend-mode', 'difference', 'important');
              children.style.setProperty('fill', darkColour, 'important');
              children.style.setProperty('stroke', darkColour, 'important');
            };
            
          }
        } 

        function changeText(text) {
          for (var i = 0; i < text.length; i++) {
            //text[i].style.color = 'red';
            text[i].style.setProperty ('color', whiteColour, 'important');
            text[i].style.setProperty ('background-color', darkColour, 'important');
          }
        }

        function changeDarkText(text) {
          for (var i = 0; i < text.length; i++) {
            //text[i].style.color = 'red';
            text[i].style.setProperty ('color', darkColour, 'important');
            text[i].style.setProperty ('background-color', whiteColour, 'important');
          }
        }
        
        function changeInput(input) {
          for (var i = 0; i < input.length; i++) {
            //text[i].style.color = 'red';
            input[i].style.setProperty ('color', darkColour, 'important');
            input[i].style.setProperty ('background-color', whiteColour, 'important');
            input[i].style.setProperty ('border', borderColorYellow, 'important');
            var children =  input[i].firstElementChild;
            if(children){
              children.style.setProperty('background-color', whiteColour, 'important');
              children.style.setProperty ('color', darkColour, 'important');
            };
          }
        }

        function changeColor(element) {
          for (var i = 0; i < element.length; i++) {
            //element[i].style.color = 'red';
            //element[i].setAttribute ('style', 'background-color: red !important;');
            element[i].style.setProperty ('background-color', darkColour, 'important');
          }
        } 


        changeText(text);
        changeText(ul);
        changeText(link);
        changeColor(body);
        changeColor(div);
        changeButton(button);
        changeButton(wpbutton);
        changeButton(classbutton);
        changeSVGEl(svgEl);
        changeInput(input);
        changeInput(select); //textarea
        changeInput(textarea);
        changeText(span); //
        changeText(option); 
        //changeDarkText(li);
        changeDarkText(select2dropdown); //link
        changeDarkText(i);

      } if(myCookie == 1){

        function changeButtonRemove(element) {
          for (var i = 0; i < element.length; i++) {
            //element[i].style.color = 'red';
            //element[i].setAttribute ('style', 'background-color: red !important;');
            element[i].style.removeProperty ('mix-blend-mode', 'difference', 'important');
            element[i].style.removeProperty ('background-color', yelloColour, 'important');
            element[i].style.removeProperty ('background', yelloColour, 'important');
            element[i].style.removeProperty ('color', darkColour, 'important');
            var children =  element[i].firstElementChild;
            if(children){
              children.style.removeProperty('background-color', darkColour, 'important');
              children.style.removeProperty ('mix-blend-mode', 'difference', 'important');
            };

            
          }
        } 

        function changeSVGElRemove(element) {
          for (var i = 0; i < element.length; i++) {
            //element[i].style.color = 'red';
            //element[i].setAttribute ('style', 'background-color: red !important;');
            let firstElementChild = element[i].firstElementChild;
            //firstElementChild.style.setProperty ('mix-blend-mode', 'difference', 'important'); 
            firstElementChild.style.removeProperty ('fill', whiteColour, 'important'); 
            firstElementChild.style.removeProperty ('stroke', whiteColour, 'important'); 
            firstElementChild.style.removeProperty ('mix-blend-mode', 'difference', 'important');
          }
        } 

        function changeTextRemove(text) {
          for (var i = 0; i < text.length; i++) {
            //text[i].style.color = 'red';
            text[i].style.removeProperty ('color', whiteColour, 'important');
            text[i].style.removeProperty ('background-color', darkColour, 'important');
          }
        } 

        function RemoveChangeDarkText(text) {
          for (var i = 0; i < text.length; i++) {
            //text[i].style.color = 'red';
            text[i].style.removeProperty ('color', darkColour, 'important');
            text[i].style.removeProperty ('background-color', whiteColour, 'important');
          }
        }

        function changeColorRemove(element) {
          for (var i = 0; i < element.length; i++) {
            //element[i].style.color = 'red';
            //element[i].setAttribute ('style', 'background-color: red !important;');
            element[i].style.removeProperty ('background-color', darkColour, 'important');
          }

        } 

        function accessRemove(element) {
          for (var i = 0; i < element.length; i++) {
            //element[i].style.color = 'red';
            //element[i].setAttribute ('style', 'background-color: red !important;');
            element[i].style.removeProperty ('background-color', whiteColour, 'important');
          }

        } 

      function inputRemove(input) {
          for (var i = 0; i < input.length; i++) {
            //text[i].style.color = 'red';
            input[i].style.removeProperty ('color', darkColour, 'important');
            input[i].style.removeProperty ('background-color', whiteColour, 'important');
            input[i].style.removeProperty ('border', borderColorYellow, 'important');
            var children =  input[i].firstElementChild;
            if(children){
              children.style.removeProperty('background-color', whiteColour, 'important');
              children.style.removeProperty ('color', darkColour, 'important');
            };
          }
        }


        changeTextRemove(text);
        changeTextRemove(ul);
        changeTextRemove(link);
        changeColorRemove(body);
        changeColorRemove(div);
        changeButtonRemove(button);
        changeButtonRemove(wpbutton);
        changeButtonRemove(classbutton);
        accessRemove(accessibilityToolBox);
        changeSVGElRemove(svgEl);
        inputRemove(input);
        inputRemove(select);
        inputRemove(select2dropdown); //textarea
        inputRemove(textarea);
        inputRemove(span);
        inputRemove(option); 
        RemoveChangeDarkText(li);
        RemoveChangeDarkText(i);

      }
      if(myCookie == 2){

        function changeButton(element) {
          for (var i = 0; i < element.length; i++) {
            //element[i].style.color = 'red';
            //element[i].setAttribute ('style', 'background-color: red !important;');
            element[i].style.removeProperty ('mix-blend-mode', 'difference', 'important');
            element[i].style.setProperty('background-color', darkColour, 'important');
            var children = element[i].firstElementChild;
            if(children){

              children.style.setProperty('background-color', 'black', 'important');

          
            };

           // firstSVGChild.style.setProperty ('background-color', 'black', 'important');
            element[i].style.setProperty('color', whiteColour, 'important');
          }
        } 
        
        function RemoveChangeDarkText(text) {
          for (var i = 0; i < text.length; i++) {
            //text[i].style.color = 'red';
            text[i].style.removeProperty ('color', darkColour, 'important');
            text[i].style.removeProperty ('background-color', whiteColour, 'important');
          }
        }

        function changeSVGEl(element) {
          for (var i = 0; i < element.length; i++) {
            //element[i].style.color = 'red';
            //element[i].setAttribute ('style', 'background-color: red !important;');
            //element[i].style.setProperty ('background-color', 'white', 'important');
            let firstElementChild = element[i].firstElementChild;
            element[i].style.removeProperty ('mix-blend-mode', 'difference', 'important');
            element[i].style.removeProperty ('background-color', darkColour, 'important');
            //element[i].style.setProperty ('mix-blend-mode', 'difference', 'important'); 
            firstElementChild.style.setProperty ('fill', darkColour, 'important'); 
            firstElementChild.style.setProperty ('stroke', darkColour, 'important'); 
          }
        } 

        function changeText(text) {
          for (var i = 0; i < text.length; i++) {
            //text[i].style.color = 'red';
            text[i].style.setProperty ('color', darkColour, 'important');
            text[i].style.setProperty ('background-color', whiteColour, 'important');
          }
        } 

        function changeColor(element) {
          for (var i = 0; i < element.length; i++) {
            //element[i].style.color = 'red';
            //element[i].setAttribute ('style', 'background-color: red !important;');
            element[i].style.setProperty ('background-color', whiteColour, 'important');
          }
        } 

        function changeInput(input) {
          for (var i = 0; i < input.length; i++) {
            //text[i].style.color = 'red';
            input[i].style.setProperty ('color', darkColour, 'important');
            input[i].style.setProperty ('background-color', whiteColour, 'important');
            input[i].style.setProperty ('border', borderColorDark, 'important');
            var children =  input[i].firstElementChild;
            if(children){
              children.style.setProperty('background-color', whiteColour, 'important');
              children.style.setProperty ('color', darkColour, 'important');
            };
          }
        }

        

        changeText(text);
        changeText(ul);
        changeText(link);
        changeColor(body);
        changeColor(div);
        changeButton(button);
        changeButton(wpbutton);
        changeButton(classbutton);
        changeSVGEl(svgEl);
        changeInput(input);
        changeInput(select);
        changeInput(select2dropdown);
        changeInput(textarea);
        changeInput(span);
        changeText(li);
        RemoveChangeDarkText(i);
      }
 }
});
}

RunColourFunction()

setInterval(function(){
  RunColourFunction() 
  console.log('secondFunction')},3000);